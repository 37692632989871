// src/components/AddPage.js
import React from 'react';

export default function AddPage() {
  return (
    <div>
      <h1>Add Page</h1>
      <p>This is the Add page.</p>
    </div>
  );
}
